section#contact {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    article {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contact-fields {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: grid;
            grid-template-columns: 1fr 2fr;
            transform: translateX(-5%);

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1.8rem 3.6rem;
                @include mobile() {
                    padding: 1.2rem 2rem;
                }

                a, span {
                    font-size: 2.4rem;
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                svg {
                    width: 30px;
                    height: 30px;
                }

            }

            .label {
                text-align: left;
                padding: 1.8rem 0;
                @include mobile() {
                    padding: 1.2rem 0;
                }
            }
        }

        a, span {
            font-size: 2.8rem;
            position: relative;
            cursor: pointer;
            transition: .2s ease-in-out;

            &:before {
                content: '';
                transition: .2s ease-in-out;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                bottom: calc(1.8rem - 7.5px);
                left: 0;
                position: absolute;
                @include gut-orange-bg();
                z-index: -1;
                opacity: 0;
            }

            &:hover {
                // color: #fff;
                &:before {
                    opacity: 1;
                    left: -20px;
                    @include mobile() {
                        left: -15px;
                    }
                }
            }
        }
    }

    i {
        font-size: 2.8rem;
    }
}