.loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 999;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 20%;
        height: 20%;

        path {
            stroke-dasharray: 1900;
            stroke-dashoffset: 1900;
            animation: loadersvg 3s infinite ease-in-out;
        }
    }
}

@keyframes loadersvg {
    45% {
        stroke-dashoffset: 100;
    }
    50% {
        stroke-dashoffset: 101;
    }

    100% {
        stroke-dashoffset: 1900;
    }
}
