@import 'variables';
@import 'loader';
@import 'section-contact';
// SETUP
html {
    font-size: 62.5%;

    &[lang="en"] [data-lang="pl"], &[lang="pl"] [data-lang="en"] {
        display: none;
    }
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &, &::before, &::after {
        box-sizing: border-box;

    }
}

body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    margin: 0;
    padding: 0;
    overflow: hidden !important;
    height: 100vh;
    width: 100vw;
    background: #fff;
    position: fixed;
}

h2 {
    font-weight: 300;
    padding: 1rem;
    margin: 0;
    font-size: 3.2rem;
    @include mobile() {
        padding: 0 0 1rem 0;
    }
}

header {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 1s $gut-velo;

    &.active {
        height: 30vh;
        @include mobile() {
            height: 50vh;
            nav {
                ul li {
                    padding: 0 0 2rem 0;
                }
            }
        }

        & + main {
            section.active {
                article {
                    max-height: 50vh;
                }
            }
        }
    }
}

main {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 1s $gut-velo;
    // @include mobile() {
    //     display: block;
    //     flex-wrap: wrap;
    //     flex-direction: column;
    // }
    section {
        min-width: 100vw;
        font-size: 3rem;
        flex: 1 0 auto;
        transition: 1s $gut-velo;
        // @include mobile() {
        //     flex: unset;
        //     transition: none;
        //     display: none;
        // }
        &.active {
            height: 50vh;
            // @include mobile() {
            //     display: none;  
            // }
        }

        article {
            text-align: center;
            transition: 1s $gut-velo;

            h2 {
                margin: 0;
            }

            p {
                max-width: 100vw;
                line-height: 1.6;
                font-size: 2.4rem;
                margin-top: .75rem;
            }
        }
    }
}

h1 {
    font-size: 4.8rem;
    margin: 0 0 2rem 0;
    cursor: default;
    position: relative;

    svg {
        transition: 1s $gut-velo;
        transform: scale(0);
        display: inline-block;
        height: 4.8rem;
        width: 4.8rem;
        vertical-align: middle;
        position: absolute;
        top: calc(50% - 24px);
        left: 0;
        opacity: 0;
    }

    span {
        margin-left: 0;
        transition: 1s $gut-velo;
        vertical-align: middle;
        font-weight: 300;
    }

    &.hide-sections {
        cursor: pointer;

        svg {
            transform: scale(1);
            opacity: 1;
        }

        span {
            margin-left: 7rem;
        }
    }
}


nav {
    min-height: 5.8rem;
    margin-top: 2rem;

    ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        @include mobile() {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        li {
            padding: 0 2rem;
            transition: 1s $gut-velo;
            @include mobile() {
                padding: 4rem 0;
            }
        }
    }

    a {
        font-size: 1.6rem;
        position: relative;

        &:before {
            content: '';
            transition: transform 1s $gut-velo, opacity .2s ease-in-out, top .2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            height: 10px;
            width: 10px;
            @include gut-orange-bg();
            z-index: -1;
            border-radius: 50%;
            left: calc(50% - 5px);
            opacity: 0;
        }

        &:hover, &.active {
            &:before {
                top: -16px;
                left: calc(50% - 5px);
                opacity: 1;
            }
        }

        &.active {
            &:before {
                transform-origin: 0 calc(100% + 16px);
                transform: rotate(360deg);
            }
        }
    }
}

a {
    color: #050505;
    text-decoration: none;
    text-transform: lowercase;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    article {
        font-family: sans-serif;
    }
    .loader {
        display: none;
    }
}


