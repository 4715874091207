$light-orange: #fcb017;
$dark-orange: #f26522;
@mixin gut-orange-bg($rotate: 45deg) {
    background: linear-gradient($rotate, $dark-orange, $light-orange);
}

$gut-velo: cubic-bezier(0.86, -0.01, 0.26, 1.01);

$laptop-max: 1600px;
$laptop-min: 1025px;
$tablet-max: 1024px;
$tablet-min: 768px;
$mobile-max: 767px;

@mixin mobile {
    @media (max-width: $mobile-max) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet-max) {
        @content;
    }
}

@mixin tablet-only {
    @media (max-width: $tablet-max) and (min-width: $tablet-min) {
        @content;
    }
}

@mixin tablet-and-greater {
    @media (min-width: $tablet-min) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: $laptop-max) {
        @content;
    }
}

@mixin laptop-only {
    @media (max-width: $laptop-max) and (min-width: $laptop-min) {
        @content;
    }
}

@mixin laptop-and-greater {
    @media (min-width: $laptop-min) {
        @content;
    }
}